import React, { useState } from "react";
import { Link } from "gatsby";

const Navigation = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="w-full items-center  flex p-5 lg:pl-0 ">
      <div className="flex justify-between w-full lg:w-11/12 mx-auto ">
        <div className="items-center flex-shrink-0 text-white mr-2 ">
          <Link
            to="/"
            className="text-xl lg:text-2xl text-gray-800 outline-none pt-2 mt-1"
          >
            <img
              src="/images/eln-logo.png"
              alt="eBrick Network"
              className="mt-2"
            />
          </Link>
        </div>

        <div className="block lg:hidden">
          <button
            className="bg-white flex items-center px-3 py-2 mt-2 border rounded text-gray-800 border-gray-400 hover:text-black hover:border-black"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } lg:flex lg:items-center lg:w-auto`}
        >
          <div className="lg:flex-grow md:items-right lg:items-center lg:justify-end ml-10 pt-3 tracking-tight">
            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5">
              <a href="#que-hacemos" className="hover:underline">
                ¿Qué hacemos?
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a href="#como-funciona" className="hover:underline">
                ¿Cómo funciona?
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a href="#quienes-somos" className="hover:underline">
                ¿Quiénes somos?
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a href="#nuestra-comunidad" className="hover:underline">
                Nuestra Comunidad
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a href="#contacto" className="hover:underline">
                Contacto
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a
                href="https://frontend.ebricknetwork.com/register/"
                className="hover:underline"
              >
                Regístrate
              </a>
            </div>

            <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
              <a
                href="https://frontend.ebricknetwork.com/"
                className="rounded-full border border-blue-400 px-4 py-1 hover:bg-blue-100"
              >
                Ingresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
