import React from "react";
import ReactPlayer from "react-player";
import Contact from "../components/Contact";

import Layout from "../components/layout";
import Navigation from "../components/Navigation";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="w-full mx-auto">
      <Navigation />
      {/* Header */}
      <div
        className="main_header w-full py-10 lg:pt-20 lg:pb-40 md:py-12"
        id="main_header"
      >
        <div className="w-10/12 lg:w-1/2 ml-8 lg:ml-20 md:ml-12 text-white">
          <h2 className="text-white text-3xl">
            Forma parte de la comunidad que te hace crecer.
          </h2>
          <p className="my-8 text-white ">
            En eBrick Network estarás en contacto con emprendedores, talentos
            creativos y propietarios de pequeñas empresas que buscan compartir,
            inspirar y expandir tus contactos. ¡Crezcamos juntos!
          </p>
          <a
            href="https://frontend.ebricknetwork.com/register/"
            className="bg-blue-900 rounded-full py-3 px-5 shadow-sm"
          >
            Conéctate con nosotros
          </a>
        </div>
      </div>
      {/* top header */}

      {/*Que hacemos */}

      <div className="que_hacemos w-full mx-auto" id="que-hacemos">
        <div className="w-10/12 mx-auto pt-10 lg:pt-16 md:pt-10 pb-10">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            ¿Qué hacemos?
          </h3>
          <h5 className="text-md md:text-lg mx-auto text-center mt-3 lg:mt-4 md:mt-3">
            ¡Te conectamos! A ti el emprendedor que busca talento freelance, y a
            ti el freelancer que está buscando aumentar sus ingresos.
          </h5>

          <div className="w-full lg:flex mx-auto mt-8">
            <div className="w-full lg:w-1/3 mt-8 text-center">
              <img src="/images/red.png" alt="Network" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">
                Amplía tu red de contactos
              </h4>
              <p>
                Expande tu negocio ofreciendo tus servicios, Ebrick Network es
                el recurso para ello.
              </p>
            </div>
            <div className="w-full lg:w-1/3 mt-8 mx-auto text-center">
              <img src="/images/pig.png" alt="Save money" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">Al alcance de todos</h4>
              <p>
                Encuentra servicios de calidad en diferentes niveles de precios.
              </p>
            </div>
            <div className="w-full lg:w-1/3 mt-8 mx-auto text-center">
              <img src="/images/cash.png" alt="Earn cash" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">Claridad en tus pagos</h4>
              <p>
                Siempre sabrás por adelantado lo que pagarás. Tu pago se
                liberará una vez que apruebes el trabajo.
              </p>
            </div>
          </div>

          <div className="mx-auto w-full lg:w-8/12 my-12 lg:mt-20 text-center">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="/video/vid.mp4"
                width="100%"
                height="100%"
                controls="true"
              />
            </div>
          </div>
        </div>
      </div>

      {/*/ que hacemos */}

      {/*banner 1*/}

      <div
        className="w-full bg-blue-900"
        style={{
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgrounSize: "cover",
          paddingTop: "40px",
          paddingBottom: "40px"
        }}
        id="sitting"
      >
        <div className=" w-full lg:w-10/12 mx-auto p-3">
          <div className="w-full lg:w-2/3">
            <img
              src="/images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "30%" }}
              className="mt-3"
            />
            <p className="text-white">
              Mejora tu emprendimiento en nuestra red, conéctate con miles de
              freelancers en todo el mundo y desarrolla tu negocio de forma
              rápida y sencilla.
            </p>
          </div>
        </div>
      </div>
      {/*/ banner 1 */}

      {/*Como funciona */}

      <div className="w-full bg-gray-200" id="como-funciona">
        <div className="w-full lg:w-10/12 mx-auto py-16">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            ¿Cómo funciona?
          </h3>

          <div className="w-full lg:flex">
            <div className="w-full lg:w-1/4 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">Regístrate</h4>
              <img src="/images/laptop.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Regístrate gratis y crea tu perfil profesional con el que podes
                ofrecer tus servicios al resto del mundo
              </p>
            </div>

            <div className="w-full lg:w-1/4 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">Sube tu portafolio</h4>
              <img src="/images/browser.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Comparte tus mejores proyectos y tu portafolio para atraer
                nuevos clientes. ¡La red se encarga de conectarlos!
              </p>
            </div>

            <div className="w-full lg:w-1/4 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">
                Sé contactado por tus clientes
              </h4>
              <img src="/images/chat.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Sé contratado en cualquier parte del mundo y usa nuestra
                plataforma para discutir los detalles con tus clientes ¡Manténte
                en contacto!
              </p>
            </div>

            <div className="w-full lg:w-1/4 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">Recibe tu pago</h4>
              <img src="/images/trophy.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                En cuanto el cliente apruebe tu proyecto, tu pago será
                transferido.
              </p>
            </div>
          </div>
          <div className="mx-auto text-center">
            <a
              href="https://frontend.ebricknetwork.com/register/"
              className="rounded-full bg-blue-900 text-white px-8 py-2"
            >
              Únete
            </a>
          </div>
        </div>
      </div>

      {/*/ Como funciona */}

      {/*Quienes Somos */}

      <div className="w-full mx-auto" id="quienes-somos">
        <div className="w-10/12 mx-auto lg:flex py-16">
          <div className="w-full lg:w-1/2">
            <img src="/images/workers.png" alt="Sharing work" />
          </div>

          <div className="w-full lg:w-1/2 px-2 lg:px-10 pt-10 lg:pt-0">
            <p className="italic text-gray-600">Únete a nuestra comunidad</p>
            <h3 className="mx-auto text-teal-500 text-3xl">¿Quiénes somos?</h3>
            <p className="text-gray-700 py-10">
              SLS Panamá se ha especializado en utilizar la tecnología como un
              facilitador para la gente, brindando soluciones que hacen que tu
              vida sea más sencilla.
            </p>

            <div className="items">
              <div className="check flex">
                <div className="w-1/5">
                  <img src="/images/check.png" alt="Check" />
                </div>
                <div className="w-4/5">
                  <p className="text-gray-600 text-sm ml-3">
                    La historia de SLS data del 2003, cuando su fundador comenzó
                    con su primer negocio de e-Commerce y Drop Shipping en los
                    Estados Unidos de América.
                  </p>
                </div>
              </div>

              <div className="check flex mt-10">
                <div className="w-1/5">
                  <img src="/images/check.png" alt="Check" />
                </div>
                <div className="w-4/5">
                  <p className="text-gray-600 text-sm ml-3">
                    Para el 2011, se crea AINELY S.A. (Uruguay) para Desarrollar
                    Software de Automatización Administrativa. Con la red y la
                    experiencia le fue bien automatizando los servicios de
                    nómina.
                  </p>
                </div>
              </div>

              <div className="check flex mt-10">
                <div className="w-1/5">
                  <img src="/images/check.png" alt="Check" />
                </div>
                <div className="w-4/5">
                  <p className="text-gray-600 text-sm ml-3">
                    En 2017 abrió operaciones en Panamá bajo Servicios
                    Logísticos y Soluciones de Panamá S.A. que ha demostrado ser
                    un buen ambiente para enfocarse en una Base Global de
                    Clientes.
                  </p>
                </div>
              </div>

              <div className="check flex mt-10">
                <div className="w-1/5">
                  <img src="/images/check.png" alt="Check" />
                </div>
                <div className="w-4/5">
                  <p className="text-gray-600 text-sm ml-3">
                    Soluciones Tecnológicas que han conectado a todo el mundo
                    para que pueda comercializar sus programas de Lealtad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Quienes Somos */}

      {/*banner 2*/}

      <div
        className="w-full bg-blue-900"
        style={{
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "40px",
          paddingBottom: "40px"
        }}
        id="banner2"
      >
        <div className=" w-full lg:w-10/12 mx-auto p-3">
          <div className="w-full lg:w-2/3">
            <img
              src="/images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "30%" }}
              className="mt-3"
            />
            <p className="text-white">
              Pon tu disponibilidad como freelancer al alcance de miles de
              emprendedores. Crea los mejores proyectos para hacer crecer a un
              emprendedor.
            </p>
          </div>
        </div>
      </div>
      {/*/ banner 2 */}

      {/*Servicios Profesionales */}

      <div className="que_hacemos w-full mx-auto" id="nuestra-comunidad">
        <div className="w-10/12 mx-auto pt-10 lg:pt-16 md:pt-10 pb-10">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            Servicios Profesionales más usados
          </h3>

          <div className="w-full lg:flex mx-auto mt-8">
            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="phone"
              >
                <h2 className=" text-white italic text-lg">
                  Mejora tus redes sociales
                </h2>

                <h3 className="text-2xl text-white italic">
                  Social Media Manager
                </h3>
              </div>
            </div>

            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="web"
              >
                <h2 className=" text-white italic text-lg">
                  Crea tu sitio web
                </h2>

                <h3 className="text-2xl text-white italic">Desarrollo Web</h3>
              </div>
            </div>

            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="ipad"
              >
                <h2 className="text-white italic text-lg">
                  Dale identidad a tu marca
                </h2>

                <h3 className="text-2xl text-white italic">Diseño Gráfico</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*/ Servicios profesionales */}

      {/*contact form*/}

      <Contact />

      {/*/contact form*/}

      {/*Footer*/}

      <div className="w-full bg-blue-800 py-16">
        <div className="w-10/12 mx-auto text-white lg:flex">
          <div className="w-full lg:w-1/3 text-sm px-4 mb-5">
            <img
              src="images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "80%" }}
              className="mt-3"
            />
            <p className="mt-3 text-white">
              Forma parte de la comunidad que te hace crecer.
            </p>
            <p className="mt-2 text-white">
              &copy; Copyright 2018 eBrick Network, All Rights Reserved.
            </p>
          </div>
          <div className="w-full lg:w-1/3 text-sm px-4 mb-4">
            <h2 className="text-2xl">Quick View</h2>
            <ul className="mt-5">
              <li className="mt-2">
                <a href="#que-hacemos">¿Qué hacemos?</a>
              </li>
              <li className="mt-2">
                <a href="#como-funciona">¿Cómo funciona?</a>
              </li>
              <li className="mt-2">
                <a href="#quienes-somos">¿Quiénes somos?</a>
              </li>
              <li className="mt-2">
                <a href="#nuestra-comunidad">Nuestra Comunidad</a>
              </li>
              <li className="mt-2">
                <a href="#contacto">Contacto</a>
              </li>
              <li className="mt-2">
                <a href="https://frontend.ebricknetwork.com/register/">
                  Regístrate
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/3 text-sm px-4">
            <h2 className="text-2xl">Contact us</h2>
            <p className="mt-3 text-white">Phone: +1 234 567 9974</p>
            <p className="mt-3 text-white">Email: info@ebricknetwork.com</p>
            <p className="mt-3 text-white">Fax: +1 234 567 65487</p>
          </div>
        </div>
      </div>

      {/*Footer*/}
    </div>
  </Layout>
);

export default IndexPage;
